import React, { useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, MenuItem, CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PhoneIcon from '@mui/icons-material/Phone';
import Header from './header';
import emailjs from 'emailjs-com';

const languages = [
  'İngilizce',
  'Almanca',
  'Fransızca',
  'İspanyolca',
  'İtalyanca',
  'Rusça',
  'Çince',
  'Japonca',
  'Korece'
];

export default function App() {
  const [code, setCode] = useState('');
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const generateCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    setCode(result);
    setOpen(true); // Open the dialog when the code is generated
  };

  const handleClose = () => {
    setOpen(false);
    setFormOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    alert('Kod kopyalandı!');
  };

  const openForm = () => {
    setFormOpen(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm('service_evsqkyi', 'template_fz7b9dk', formRef.current, 'M7YBH7orqCk26YpUO')
      .then(
        () => {
          alert('Teşekkürler! Formunuz başarıyla gönderildi.');
          setLoading(false);
          handleClose();
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false);
        }
      );
  };

  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/off.png"} style={{ maxHeight: "70px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Size Özel <b>%25 İndirim</b> Kodunuz:</Typography>
                  <Button variant="contained" sx={{ width: "100%" }} onClick={generateCode}>Kod Al</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"} alignItems={"center"} sx={{ marginTop: "15px" }}>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/Brimston.webp"} style={{ maxHeight: "100px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Çocuklarınız için en uygun özel eğitmenlerle çocuğunuzu geleceğe hazırlıyoruz.</Typography>
                  <Button variant="contained" sx={{ width: "100%" }} onClick={openForm}>Çocuk Dil Eğitimi</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px", marginTop: "10px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/Brimston-1.webp"} style={{ maxHeight: "100px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Seviyeniz ne olursa olsun,size en uygun eğitimi veriyor.Başarıyı garanti ediyoruz.</Typography>
                  <Button variant="contained" sx={{ width: "100%" }} onClick={openForm}>Erişkin Dil Eğitimi</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px", marginTop: "10px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/Brimston-2.webp"} style={{ maxHeight: "100px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Ücretsiz Seviye Tespit sınavına hemen katılabilir,dil seviyenizi ölçebilirsiniz.</Typography>
                  <Button variant="contained" sx={{ width: "100%", fontSize: "0.8rem" }} onClick={openForm}>Ücretsiz Seviye Tespit Sınavı</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px", marginTop: "10px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/lesson.webp"} style={{ maxHeight: "100px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Ücretsiz tanışma dersine hemen katılın,American Life Ümraniye de ayrıcalıklı eğitimin farkına varın.</Typography>
                  <Button variant="contained" sx={{ width: "100%" }} onClick={openForm}>Ücretsiz Tanışma Dersi</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px", marginTop: "10px" }}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={4} md={4} sm={4} lg={4}>
                  <img src={"/image/Brimston-6.webp"} style={{ maxHeight: "100px" }} />
                </Grid>
                <Grid item xs={8} md={8} sm={8} lg={8}>
                  <Typography>Hemen iletişme geçip detaylı bilgi alın.Sezon indirimlerinden Faydalanın.</Typography>
                  <Button variant="contained" sx={{ width: "100%" }} onClick={openForm}>Hemen İletişime Geç</Button>
                </Grid>
              </Grid>
              </Grid>
            <Grid item xs={12} md={12} sm={12} lg={12} sx={{ backgroundColor: "white", borderRadius: "10px", padding: "3px 15px", marginTop: "10px" }}>
            <Button 
      variant="contained" 
      sx={{ width: "100%" }} 
      href="tel:+905011576244"
      startIcon={<PhoneIcon />}
    >
      +90 501 157 62 44
    </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>İndirim Kodunuz</DialogTitle>
        <DialogContent>
          <Typography variant="h6">{code}</Typography>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
          <Typography sx={{ mt: 2 }}>
            Lütfen kodunuzu güvenli bir yerde saklayınız. İndirim için lütfen kodu bizimle paylaşın.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={formOpen} onClose={handleClose}>
        <DialogTitle>Bilgi Formu</DialogTitle>
        <DialogContent>
          <form ref={formRef} onSubmit={sendEmail}>
            <TextField
              name="language"
              select
              label="Öğrenmek İstediğiniz Dil"
              fullWidth
              margin="normal"
              required
            >
              {languages.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="name"
              label="Adınız Soyadınız"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="phone"
              label="Telefon Numaranız"
              fullWidth
              margin="normal"
              required
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Gönder'}
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Kapat</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
