import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, Button } from '@mui/material';

export default function App() {
  return (
    <Grid container sx={{backgroundColor:"White"}}>
        <img src={"/image/logo.webp"} style={{maxWidth:"250px"}}></img>
    </Grid>
    );
}
